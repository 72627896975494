import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { displayReadCivilRecordForm } from "../../../auto/js/forms/CivilRecordForm";
import { ISSUED_DOCUMENTS_ORDER_BY_DOCUMENT_ID, ISSUED_DOCUMENTS_ORDER_BY_VITAL_RECORD_ID } from "../../../auto/js/metadata/IssuedDocumentsOrderBy";
import { issuedIdCardColumns } from "../../../main/js/lists/IssuedIdCardListCommon";
import { buildData } from "../../../main/js/lists/IssuedIdCardListCommon";

export const displayIdCardList = () => {
    let IdCardList = createTableComponent(issuedIdCardColumns);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <IdCardList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} />
	});
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                     rowData.vitalRecordId = rowData.vitalRecordId.replaceAll("-", "")
                    displayReadCivilRecordForm(rowData.vitalRecordId);
                }
        }
    )
    return actions;
}