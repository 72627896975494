import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";

import * as Yup from 'yup';

import '../pages/printable.css';

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = { 1: "SINGLE", 2: "MARRIED", 3: "DIVORCED", 4: "WIDOWED" };

export const civilRecordFormfields = [
	{ name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
	{ name: "id", type: "number", x: 1, y: 3, layout: "col-md-12" },
	{ name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6", 
		"validation": Yup.string().nullable().default(undefined).min(2, 'First name must be at least two characters long').required('First name is required')},
	{ name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
	{ name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
	{ name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, 'Last name must be at least two characters long').required('Last name is required')},
	{name: "fifthname", type: "text", x:1, y: 6, layout:"col-md-12"},
	{ name: "gender", type: "select", x: 1, y: 7, layout: "col-md-6", metadata: () => gender },
	{ name: "maritalStatus", type: "select", x: 2, y: 7, layout: "col-md-6", metadata: () => maritalStatus },
	{ name: "birthdate", type: "date", x: 1, y: 8, layout: "col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')},
	{ name: "birthCountry", type: "autocomplete", x: 2, y: 8, layout: "col-md-6", metadata: () => metadataLoader.get('country') },
	{ name: "notes", type: "text", x: 1, y: 9, layout: "col-md-12" }
];


export const loadFormData = async (id) => {
	return await rest.read('vital-record', id).then(response => {
		let form = response;
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
		
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}
