import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_BIRTH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import * as Yup from 'yup';

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};

export const birthRegistrationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "firstname", type: "text", x:1, y:3, layout:"col-md-6", 
		"validation": Yup.string().nullable().default(undefined).min(2, t`First name must be at least two characters long`).max(14, t`First name must be less than fifteen characters long`).required(t`First name is required`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
    {name: "secondname", type: "text", x:2, y:3, layout:"col-md-6", 
	    "validation": Yup.string().nullable().default(undefined).min(2, t`Second name must be at least four characters long`).max(14, t`Second name must be less than fifteen characters long`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
    {name: "thirdname", type: "text", x:1, y: 4, layout:"col-md-6", 
	    "validation": Yup.string().nullable().default(undefined).min(2, t`Third name must be at least four characters long`).max(14, t`Third name must be less than fifteen characters long`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
    {name: "fourthname", type: "text", x:2, y: 4, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(2, t`Family name must be at least four characters long`).max(14, t`Family name must be less than fifteen characters long`).required(t`Family name is required`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
	{name: "fifthname", type: "text", x:1, y: 5, layout:"col-md-12",
	    "validation": Yup.string().nullable().default(undefined).min(2, t`Marriage name must be at least four characters long`).max(14, t`Marriage name must be less than fifteen characters long`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
    {name: "gender", type: "select", x:1, y: 6, layout:"col-md-6", metadata: () => gender,
		"validation": Yup.string().nullable().default(undefined).required(t`Gender is required`)},
    {name: "maritalStatus", type: "select", x:2, y:6, layout:"col-md-6", metadata: () => maritalStatus},
    {name: "birthdate", type: "date", x:1, y: 7, layout:"col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')
	},
	{name: "birthCountry", type: "autocomplete", x:2, y:7, layout:"col-md-12", metadata: () => metadataLoader.get('country')},
    {name: "notes", type: "text", x:1, y:8, layout:"col-md-12"},
];

export const saveBirthRegistrationForm = async (formData) => {
    let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
    dto.mtlbType = MTLB_TYPE_BIRTH_REGISTRATION;
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
    try {
		return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadBirthRegistrationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = {key: response.birthCountry, value: countryMetadata[response.birthCountry]}
		}
        if (response.birthTime !== null)
			response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
		
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingBirthRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Birth Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateBirthRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
    try {
        return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Birth Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteBirthRegistrationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted birth registration application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedBirthRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Birth Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectBirthRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyBirthRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyBirthRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const buildBirthRegistrationFormEmptyObject  = () => {
    const empty = buildEmptyObject(birthRegistrationFields);
	empty['birthCountry'] = {key: 179, value: 'Trinidad and Tobago'}
	return empty;
}

export const loadCivilStatusMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-attachment'] = {};
	filter['civil-status-mtlb-attachment']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb-attachment', filter)
}

const form2dto = (formData, dto) => {
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
}