import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { setContextualOptions } from '../../../auto/js/widgets/RibbonTab'; 
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { buildEmptyObject, getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../widgets/FileUploader";

import { createTableComponent } from "../widgets/TableComponent";
import { civilRecordFormfields } from "../../../main/js/forms/CivilRecordFormCommon"
import { AttachmentsArea } from "../widgets";
import * as Yup from 'yup';

import '../../css/printable.css';
import QRCode from "react-qr-code";
import { MTLB_TYPE_ID_CARD } from "../../../auto/js/metadata/MtlbType";

export const displayReadCivilRecordForm = (id) => {
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid,
		view: () => {
			return (
				<>
					<CivilRecordForm key={uuid} loadData={async () => loadFormData(id)} readOnly buttons={() => null}/>
				</>
			)
		}
	});
}

const loadFormData = async (id) => {
	return await rest.read('vital-record', id).then(async response => {
		let form = response;
		let faceUrl = null;
		if (response.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + response.faceId;
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (faceUrl != null) ? faceUrl : '/public/avatar.png', isEmpty: true };
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}