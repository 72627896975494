import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { t, rest } from '../services';

import toolbarStyles from './ToolBar.styl';
import { LanguageSelector } from '../widgets/LanguageSelector';

import { whoami } from '../users/UserInfo';
import { UserAvatar } from '../users/Avatars';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function ToolBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar color="inherit" className={toolbarStyles.toolbar} >
                <div style={{maxWidth: '200px', marginRight: 'auto'}}></div>
            <LanguageSelector languages={props.languages}/>
			{UserAvatar(whoami())}
            <Button
            color="inherit"
            className={toolbarStyles.navbarBtn}
            onClick={() => {rest.oidcService.logout();}}>
          <FontAwesomeIcon icon="sign-out-alt" style={{ fontSize: '1.75em' }} title={t`Sign out`} />
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
