export const pojoMetadata = {
			'occupation': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "select", metadata: "occupation-metadata"  },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				return data
			}
		},
		'death-record-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'digitalization-queue-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'occupation-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				return data
			}
		},
		'removal-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "faceImageType", name: "face_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "fingerImageType", name: "finger_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorEmail", name: "requestor_email", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorFace", name: "requestor_face", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorFingerWhich", name: "requestor_finger_which", editable: true, resizable: true, type: "number" },
			   	{ key: "requestorFingerprint", name: "requestor_fingerprint", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorPhone", name: "requestor_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorVitalRecordId", name: "requestor_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.reportedId == "")
					data.reportedId = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.requestorEmail == "")
					data.requestorEmail = null;
				if(data.requestorPhone == "")
					data.requestorPhone = null;
				return data
			}
		},
		'civil-status-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "medicalNotification", name: "medical_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'removal-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'legacy-id-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "newId", name: "new_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
		'province': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'face-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'face-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'reg-area': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "constituencyId", name: "constituency_id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'vital-record-note': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				return data
			}
		},
		'occupation-metadata-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'administrative-update-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'death-record-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'vital-record-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'states-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "regionId", name: "region_id", editable: true, resizable: true, type: "select", metadata: "regions-metadata"  },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'regions-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "countryId", name: "country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'removal-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'email-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				return data
			}
		},
		'user-preferences-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'removal-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'death-record-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'vital-record-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "inactive", name: "inactive", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "markedInactive", name: "marked_inactive", editable: true, resizable: true, type: "date" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.markedInactive != null && data.markedInactive instanceof Date)
					data.markedInactive = data.markedInactive.getFullYear() + "-" + ('0' + (data.markedInactive.getMonth() + 1)).slice(-2) + "-" + ('0' + data.markedInactive.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'removal-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'signature': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'administrative-update-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "isDonor", name: "is_donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'union-record-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'ward': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "constituencyId", name: "constituency_id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'union-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1BirthPlace", name: "partner1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1IndividualMelanesianName", name: "partner1_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MaritalStatus", name: "partner1_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Occupation", name: "partner1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1ResidenceAddress", name: "partner1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2BirthPlace", name: "partner2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2IndividualMelanesianName", name: "partner2_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MaritalStatus", name: "partner2_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Occupation", name: "partner2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2ResidenceAddress", name: "partner2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRegime", name: "union_regime", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1BirthPlace", name: "witness1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Occupation", name: "witness1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1ResidenceAddress", name: "witness1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Surname", name: "witness1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1VitalRecordId", name: "witness1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2BirthPlace", name: "witness2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Occupation", name: "witness2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2ResidenceAddress", name: "witness2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Surname", name: "witness2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2VitalRecordId", name: "witness2_vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1BirthPlace == "")
					data.partner1BirthPlace = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1IndividualMelanesianName == "")
					data.partner1IndividualMelanesianName = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Occupation == "")
					data.partner1Occupation = null;
				if(data.partner1ResidenceAddress == "")
					data.partner1ResidenceAddress = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2BirthPlace == "")
					data.partner2BirthPlace = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2IndividualMelanesianName == "")
					data.partner2IndividualMelanesianName = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Occupation == "")
					data.partner2Occupation = null;
				if(data.partner2ResidenceAddress == "")
					data.partner2ResidenceAddress = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				if(data.witness1BirthPlace == "")
					data.witness1BirthPlace = null;
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Occupation == "")
					data.witness1Occupation = null;
				if(data.witness1ResidenceAddress == "")
					data.witness1ResidenceAddress = null;
				if(data.witness1Surname == "")
					data.witness1Surname = null;
				if(data.witness2BirthPlace == "")
					data.witness2BirthPlace = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Occupation == "")
					data.witness2Occupation = null;
				if(data.witness2ResidenceAddress == "")
					data.witness2ResidenceAddress = null;
				if(data.witness2Surname == "")
					data.witness2Surname = null;
				return data
			}
		},
		'administrative-information-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'vital-record-note-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				return data
			}
		},
		'phone-number-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'country-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'civil-status-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "medicalNotification", name: "medical_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'death-record-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'physical-afis-mapper': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'polling-units-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "registrationAreaId", name: "registration_area_id", editable: true, resizable: true, type: "select", metadata: "registration-areas-metadata"  },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'union-record-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'administrative-update-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'email': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				return data
			}
		},
		'address': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addressType", name: "address_type", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "districtName", name: "district_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fromDate", name: "from_date", editable: true, resizable: true, type: "date" },
			   	{ key: "islandName", name: "island_name", editable: true, resizable: true, type: "text" },
			   	{ key: "locationCode", name: "location_code", editable: true, resizable: true, type: "text" },
			   	{ key: "locationName", name: "location_name", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationName", name: "polling_station_name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceName", name: "province_name", editable: true, resizable: true, type: "text" },
			   	{ key: "toDate", name: "to_date", editable: true, resizable: true, type: "date" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zoneName", name: "zone_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.districtName == "")
					data.districtName = null;
				if(data.fromDate != null && data.fromDate instanceof Date)
					data.fromDate = data.fromDate.getFullYear() + "-" + ('0' + (data.fromDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fromDate.getDate()).slice(-2);
				if(data.islandName == "")
					data.islandName = null;
				if(data.locationCode == "")
					data.locationCode = null;
				if(data.locationName == "")
					data.locationName = null;
				if(data.pollingStationName == "")
					data.pollingStationName = null;
				if(data.provinceName == "")
					data.provinceName = null;
				if(data.toDate != null && data.toDate instanceof Date)
					data.toDate = data.toDate.getFullYear() + "-" + ('0' + (data.toDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.toDate.getDate()).slice(-2);
				if(data.zoneName == "")
					data.zoneName = null;
				return data
			}
		},
		'audit-log': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "actionTime", name: "action_time", editable: true, resizable: true, type: "time" },
			   	{ key: "actionType", name: "action_type", editable: true, resizable: true, type: "text" },
			   	{ key: "rowPk", name: "row_pk", editable: true, resizable: true, type: "number" },
			   	{ key: "tableName", name: "table_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.actionType == "")
					data.actionType = null;
				if(data.tableName == "")
					data.tableName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'id-mtlb-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "mtlbLocation", name: "mtlb_location", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestedIdType", name: "requested_id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.mtlbLocation == "")
					data.mtlbLocation = null;
				if(data.notes == "")
					data.notes = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'administrative-update-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'signature-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'legacy-id-trash': {
			columns: [
			   	{ key: "newId", name: "new_id", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
		'trinidad-e-id-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilRegistrationAuthorityId", name: "civil_registration_authority_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "drivingLicenceId", name: "driving_licence_id", editable: true, resizable: true, type: "number" },
			   	{ key: "passportId", name: "passport_id", editable: true, resizable: true, type: "text" },
			   	{ key: "password", name: "password", editable: true, resizable: true, type: "password" },
			   	{ key: "voterId", name: "voter_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.passportId == "")
					data.passportId = null;
				if(data.password == "")
					data.password = null;
				return data
			}
		},
		'face': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'administrative-information': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "select", metadata: "occupation-metadata"  },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'removal-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "removalMtlbId", name: "removal_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'union-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "firstWitnessId", name: "first_witness_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "secondWitnessId", name: "second_witness_id", editable: true, resizable: true, type: "number" },
			   	{ key: "unionType", name: "union_type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				return data
			}
		},
		'vital-record-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'user-account': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'id-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "inactive", name: "inactive", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "markedInactive", name: "marked_inactive", editable: true, resizable: true, type: "date" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.markedInactive != null && data.markedInactive instanceof Date)
					data.markedInactive = data.markedInactive.getFullYear() + "-" + ('0' + (data.markedInactive.getMonth() + 1)).slice(-2) + "-" + ('0' + data.markedInactive.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'country': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'id-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "mtlbLocation", name: "mtlb_location", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestedIdType", name: "requested_id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.mtlbLocation == "")
					data.mtlbLocation = null;
				if(data.notes == "")
					data.notes = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'union-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1BirthPlace", name: "partner1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1IndividualMelanesianName", name: "partner1_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MaritalStatus", name: "partner1_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Occupation", name: "partner1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1ResidenceAddress", name: "partner1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2BirthPlace", name: "partner2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2IndividualMelanesianName", name: "partner2_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MaritalStatus", name: "partner2_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Occupation", name: "partner2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2ResidenceAddress", name: "partner2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRegime", name: "union_regime", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1BirthPlace", name: "witness1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Occupation", name: "witness1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1ResidenceAddress", name: "witness1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Surname", name: "witness1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1VitalRecordId", name: "witness1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2BirthPlace", name: "witness2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Occupation", name: "witness2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2ResidenceAddress", name: "witness2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Surname", name: "witness2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2VitalRecordId", name: "witness2_vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1BirthPlace == "")
					data.partner1BirthPlace = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1IndividualMelanesianName == "")
					data.partner1IndividualMelanesianName = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Occupation == "")
					data.partner1Occupation = null;
				if(data.partner1ResidenceAddress == "")
					data.partner1ResidenceAddress = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2BirthPlace == "")
					data.partner2BirthPlace = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2IndividualMelanesianName == "")
					data.partner2IndividualMelanesianName = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Occupation == "")
					data.partner2Occupation = null;
				if(data.partner2ResidenceAddress == "")
					data.partner2ResidenceAddress = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				if(data.witness1BirthPlace == "")
					data.witness1BirthPlace = null;
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Occupation == "")
					data.witness1Occupation = null;
				if(data.witness1ResidenceAddress == "")
					data.witness1ResidenceAddress = null;
				if(data.witness1Surname == "")
					data.witness1Surname = null;
				if(data.witness2BirthPlace == "")
					data.witness2BirthPlace = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Occupation == "")
					data.witness2Occupation = null;
				if(data.witness2ResidenceAddress == "")
					data.witness2ResidenceAddress = null;
				if(data.witness2Surname == "")
					data.witness2Surname = null;
				return data
			}
		},
		'death-record-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'civil-status-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "medicalNotification", name: "medical_notification", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1Id", name: "witness1_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2Id", name: "witness2_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'administrative-information-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeInformationId", name: "administrative_information_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'occupation-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'digitalization-queue': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'fingerprints-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'union-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionMtlbId", name: "union_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'address-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "districtName", name: "district_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fromDate", name: "from_date", editable: true, resizable: true, type: "date" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "islandName", name: "island_name", editable: true, resizable: true, type: "text" },
			   	{ key: "locationName", name: "location_name", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationName", name: "polling_station_name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceName", name: "province_name", editable: true, resizable: true, type: "text" },
			   	{ key: "toDate", name: "to_date", editable: true, resizable: true, type: "date" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zoneName", name: "zone_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.districtName == "")
					data.districtName = null;
				if(data.fromDate != null && data.fromDate instanceof Date)
					data.fromDate = data.fromDate.getFullYear() + "-" + ('0' + (data.fromDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.fromDate.getDate()).slice(-2);
				if(data.islandName == "")
					data.islandName = null;
				if(data.locationName == "")
					data.locationName = null;
				if(data.pollingStationName == "")
					data.pollingStationName = null;
				if(data.provinceName == "")
					data.provinceName = null;
				if(data.toDate != null && data.toDate instanceof Date)
					data.toDate = data.toDate.getFullYear() + "-" + ('0' + (data.toDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.toDate.getDate()).slice(-2);
				if(data.zoneName == "")
					data.zoneName = null;
				return data
			}
		},
		'administrative-information-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'digitalization-queue-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'union-record-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRecordId", name: "union_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'id-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'fingerprints': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'union-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "unionMtlbId", name: "union_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'civil-status-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'vital-record-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "inactive", name: "inactive", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "markedInactive", name: "marked_inactive", editable: true, resizable: true, type: "date" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.markedInactive != null && data.markedInactive instanceof Date)
					data.markedInactive = data.markedInactive.getFullYear() + "-" + ('0' + (data.markedInactive.getMonth() + 1)).slice(-2) + "-" + ('0' + data.markedInactive.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'administrative-update-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'phone-number': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'registration-areas-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "localGovernmentId", name: "local_government_id", editable: true, resizable: true, type: "select", metadata: "local-governments-metadata"  },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'vital-record-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'id-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'civil-status-mtlb-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'removal-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "faceImageType", name: "face_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "fingerImageType", name: "finger_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorEmail", name: "requestor_email", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorFace", name: "requestor_face", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorFingerWhich", name: "requestor_finger_which", editable: true, resizable: true, type: "number" },
			   	{ key: "requestorFingerprint", name: "requestor_fingerprint", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorPhone", name: "requestor_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorVitalRecordId", name: "requestor_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.reportedId == "")
					data.reportedId = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.requestorEmail == "")
					data.requestorEmail = null;
				if(data.requestorPhone == "")
					data.requestorPhone = null;
				return data
			}
		},
		'civil-status-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'keys': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "pgp1", name: "pgp1", editable: true, resizable: true, type: "number" },
			   	{ key: "pgp2", name: "pgp2", editable: true, resizable: true, type: "number" },
			   	{ key: "pgp3", name: "pgp3", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'polling-stations': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "population", name: "population", editable: true, resizable: true, type: "number" },
			   	{ key: "regAreaId", name: "reg_area_id", editable: true, resizable: true, type: "number" },
			   	{ key: "wardId", name: "ward_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'id-mtlb-attachment-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'e-id-issued-documents': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				return data
			}
		},
		'death-record-note-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				return data
			}
		},
		'civil-status-mtlb-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'fingerprints-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "physicalId", name: "physical_id", editable: true, resizable: true, type: "number" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'ward-municipal': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "pollingStationId", name: "polling_station_id", editable: true, resizable: true, type: "number" },
			   	{ key: "wardId", name: "ward_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'id-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deduplicationMtlbId", name: "deduplication_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "faceMimeType", name: "face_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "fingersMimeType", name: "fingers_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "leftIndexFinger", name: "left_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftMiddleFinger", name: "left_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftPinky", name: "left_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftRingFinger", name: "left_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "leftThumb", name: "left_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "mtlbLocation", name: "mtlb_location", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestedIdType", name: "requested_id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "rightIndexFinger", name: "right_index_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightMiddleFinger", name: "right_middle_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightPinky", name: "right_pinky", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightRingFinger", name: "right_ring_finger", editable: true, resizable: true, type: "blob" },
			   	{ key: "rightThumb", name: "right_thumb", editable: true, resizable: true, type: "blob" },
			   	{ key: "signature", name: "signature", editable: true, resizable: true, type: "blob" },
			   	{ key: "signatureMimeType", name: "signature_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.faceMimeType == "")
					data.faceMimeType = null;
				if(data.fingersMimeType == "")
					data.fingersMimeType = null;
				if(data.mtlbLocation == "")
					data.mtlbLocation = null;
				if(data.notes == "")
					data.notes = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.signatureMimeType == "")
					data.signatureMimeType = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'local-governments-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "stateId", name: "state_id", editable: true, resizable: true, type: "select", metadata: "states-metadata"  },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'removal-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "afisId", name: "afis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "faceImageType", name: "face_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "fingerImageType", name: "finger_image_type", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorEmail", name: "requestor_email", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorFace", name: "requestor_face", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorFingerWhich", name: "requestor_finger_which", editable: true, resizable: true, type: "number" },
			   	{ key: "requestorFingerprint", name: "requestor_fingerprint", editable: true, resizable: true, type: "blob" },
			   	{ key: "requestorPhone", name: "requestor_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "requestorVitalRecordId", name: "requestor_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.reportedId == "")
					data.reportedId = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.requestorEmail == "")
					data.requestorEmail = null;
				if(data.requestorPhone == "")
					data.requestorPhone = null;
				return data
			}
		},
		'death-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "number" },
			   	{ key: "birthCountry", name: "birth_country", editable: true, resizable: true, type: "number" },
			   	{ key: "birthDayUnknown", name: "birth_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthMonthUnknown", name: "birth_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthTime", name: "birth_time", editable: true, resizable: true, type: "time" },
			   	{ key: "birthTimeUnknown", name: "birth_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthYearUnknown", name: "birth_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "cardId", name: "card_id", editable: true, resizable: true, type: "number" },
			   	{ key: "commonIdNumber", name: "common_id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "dateOfDeath", name: "date_of_death", editable: true, resizable: true, type: "date" },
			   	{ key: "deathDayUnknown", name: "death_day_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathMonthUnknown", name: "death_month_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathTimeUnknown", name: "death_time_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "deathYearUnknown", name: "death_year_unknown", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "disability", name: "disability", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donor", name: "donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "donorNotes", name: "donor_notes", editable: true, resizable: true, type: "text" },
			   	{ key: "faceId", name: "face_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fatherId", name: "father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fifthname", name: "fifthname", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "fourthname", name: "fourthname", editable: true, resizable: true, type: "text" },
			   	{ key: "gender", name: "gender", editable: true, resizable: true, type: "number" },
			   	{ key: "isDeleted", name: "is_deleted", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "maritalStatus", name: "marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "motherId", name: "mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "otherCitizenshipCsv", name: "other_citizenship_csv", editable: true, resizable: true, type: "text" },
			   	{ key: "primaryCitizenship", name: "primary_citizenship", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedFatherName", name: "reported_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedMotherName", name: "reported_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "secondname", name: "secondname", editable: true, resizable: true, type: "text" },
			   	{ key: "thirdname", name: "thirdname", editable: true, resizable: true, type: "text" },
			   	{ key: "timeOfDeath", name: "time_of_death", editable: true, resizable: true, type: "time" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "number" },
			   	{ key: "voter", name: "voter", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.commonIdNumber == "")
					data.commonIdNumber = null;
				if(data.dateOfDeath != null && data.dateOfDeath instanceof Date)
					data.dateOfDeath = data.dateOfDeath.getFullYear() + "-" + ('0' + (data.dateOfDeath.getMonth() + 1)).slice(-2) + "-" + ('0' + data.dateOfDeath.getDate()).slice(-2);
				if(data.donorNotes == "")
					data.donorNotes = null;
				if(data.fifthname == "")
					data.fifthname = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.fourthname == "")
					data.fourthname = null;
				if(data.notes == "")
					data.notes = null;
				if(data.otherCitizenshipCsv == "")
					data.otherCitizenshipCsv = null;
				if(data.reportedFatherName == "")
					data.reportedFatherName = null;
				if(data.reportedMotherName == "")
					data.reportedMotherName = null;
				if(data.secondname == "")
					data.secondname = null;
				if(data.thirdname == "")
					data.thirdname = null;
				return data
			}
		},
		'legacy-id': {
			columns: [
			   	{ key: "newId", name: "new_id", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
		'country-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'physical': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "message", name: "message", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.message == "")
					data.message = null;
				return data
			}
		},
		'death-record-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'death-record-note': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "addedBy", name: "added_by", editable: true, resizable: true, type: "text" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.addedBy == "")
					data.addedBy = null;
				if(data.content == "")
					data.content = null;
				return data
			}
		},
		'administrative-update-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "isDonor", name: "is_donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "select", metadata: "occupation-metadata"  },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'occupation-metadata-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'signature-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "idMtlbId", name: "id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "image", name: "image", editable: true, resizable: true, type: "blob" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'union-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "declaredDate", name: "declared_date", editable: true, resizable: true, type: "date" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbStatusData", name: "mtlb_status_data", editable: true, resizable: true, type: "json" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1BirthPlace", name: "partner1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1FatherId", name: "partner1_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1FatherName", name: "partner1_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Firstname", name: "partner1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1IndividualMelanesianName", name: "partner1_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1MaritalStatus", name: "partner1_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherId", name: "partner1_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner1MotherName", name: "partner1_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Occupation", name: "partner1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1ResidenceAddress", name: "partner1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1Surname", name: "partner1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner1VitalRecordId", name: "partner1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2BirthPlace", name: "partner2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2FatherId", name: "partner2_father_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2FatherName", name: "partner2_father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Firstname", name: "partner2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2IndividualMelanesianName", name: "partner2_individual_melanesian_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2MaritalStatus", name: "partner2_marital_status", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherId", name: "partner2_mother_id", editable: true, resizable: true, type: "number" },
			   	{ key: "partner2MotherName", name: "partner2_mother_name", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Occupation", name: "partner2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2ResidenceAddress", name: "partner2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2Surname", name: "partner2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "partner2VitalRecordId", name: "partner2_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "rites", name: "rites", editable: true, resizable: true, type: "text" },
			   	{ key: "unionRegime", name: "union_regime", editable: true, resizable: true, type: "number" },
			   	{ key: "witness1BirthPlace", name: "witness1_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Firstname", name: "witness1_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Occupation", name: "witness1_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1ResidenceAddress", name: "witness1_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1Surname", name: "witness1_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness1VitalRecordId", name: "witness1_vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "witness2BirthPlace", name: "witness2_birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Firstname", name: "witness2_firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Occupation", name: "witness2_occupation", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2ResidenceAddress", name: "witness2_residence_address", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2Surname", name: "witness2_surname", editable: true, resizable: true, type: "text" },
			   	{ key: "witness2VitalRecordId", name: "witness2_vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.declaredDate != null && data.declaredDate instanceof Date)
					data.declaredDate = data.declaredDate.getFullYear() + "-" + ('0' + (data.declaredDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.declaredDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				if(data.partner1BirthPlace == "")
					data.partner1BirthPlace = null;
				if(data.partner1FatherName == "")
					data.partner1FatherName = null;
				if(data.partner1Firstname == "")
					data.partner1Firstname = null;
				if(data.partner1IndividualMelanesianName == "")
					data.partner1IndividualMelanesianName = null;
				if(data.partner1MotherName == "")
					data.partner1MotherName = null;
				if(data.partner1Occupation == "")
					data.partner1Occupation = null;
				if(data.partner1ResidenceAddress == "")
					data.partner1ResidenceAddress = null;
				if(data.partner1Surname == "")
					data.partner1Surname = null;
				if(data.partner2BirthPlace == "")
					data.partner2BirthPlace = null;
				if(data.partner2FatherName == "")
					data.partner2FatherName = null;
				if(data.partner2Firstname == "")
					data.partner2Firstname = null;
				if(data.partner2IndividualMelanesianName == "")
					data.partner2IndividualMelanesianName = null;
				if(data.partner2MotherName == "")
					data.partner2MotherName = null;
				if(data.partner2Occupation == "")
					data.partner2Occupation = null;
				if(data.partner2ResidenceAddress == "")
					data.partner2ResidenceAddress = null;
				if(data.partner2Surname == "")
					data.partner2Surname = null;
				if(data.place == "")
					data.place = null;
				if(data.rites == "")
					data.rites = null;
				if(data.witness1BirthPlace == "")
					data.witness1BirthPlace = null;
				if(data.witness1Firstname == "")
					data.witness1Firstname = null;
				if(data.witness1Occupation == "")
					data.witness1Occupation = null;
				if(data.witness1ResidenceAddress == "")
					data.witness1ResidenceAddress = null;
				if(data.witness1Surname == "")
					data.witness1Surname = null;
				if(data.witness2BirthPlace == "")
					data.witness2BirthPlace = null;
				if(data.witness2Firstname == "")
					data.witness2Firstname = null;
				if(data.witness2Occupation == "")
					data.witness2Occupation = null;
				if(data.witness2ResidenceAddress == "")
					data.witness2ResidenceAddress = null;
				if(data.witness2Surname == "")
					data.witness2Surname = null;
				return data
			}
		},
		'administrative-information-attachment-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeInformationId", name: "administrative_information_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'administrative-update-mtlb-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "city", name: "city", editable: true, resizable: true, type: "text" },
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "isDonor", name: "is_donor", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "numberOfAttachments", name: "number_of_attachments", editable: true, resizable: true, type: "number" },
			   	{ key: "occupationId", name: "occupation_id", editable: true, resizable: true, type: "number" },
			   	{ key: "otherPhone", name: "other_phone", editable: true, resizable: true, type: "text" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "reportedId", name: "reported_id", editable: true, resizable: true, type: "number" },
			   	{ key: "reportedLegacyId", name: "reported_legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceCountryId", name: "residence_country_id", editable: true, resizable: true, type: "number" },
			   	{ key: "residenceLocation", name: "residence_location", editable: true, resizable: true, type: "text" },
			   	{ key: "streetAddress", name: "street_address", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "zipCode", name: "zip_code", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.city == "")
					data.city = null;
				if(data.email == "")
					data.email = null;
				if(data.otherPhone == "")
					data.otherPhone = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.reportedLegacyId == "")
					data.reportedLegacyId = null;
				if(data.residenceLocation == "")
					data.residenceLocation = null;
				if(data.streetAddress == "")
					data.streetAddress = null;
				if(data.zipCode == "")
					data.zipCode = null;
				return data
			}
		},
		'administrative-update-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeUpdateMtlbId", name: "administrative_update_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'witness': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "surname", name: "surname", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.surname == "")
					data.surname = null;
				return data
			}
		},
		'civil-status-mtlb-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'user-count': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "count", name: "count", editable: true, resizable: true, type: "number" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'constituency': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceId", name: "province_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'administrative-information-attachment': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "administrativeInformationId", name: "administrative_information_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "blob" },
			   	{ key: "fileName", name: "file_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.fileName == "")
					data.fileName = null;
				return data
			}
		},
		'issued-documents': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "documentId", name: "document_id", editable: true, resizable: true, type: "number" },
			   	{ key: "documentType", name: "document_type", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'death-record-tag-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'locations': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "activeLocation", name: "active_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "customLocation", name: "custom_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "districtCode", name: "district_code", editable: true, resizable: true, type: "text" },
			   	{ key: "districtName", name: "district_name", editable: true, resizable: true, type: "text" },
			   	{ key: "islandCode", name: "island_code", editable: true, resizable: true, type: "text" },
			   	{ key: "islandName", name: "island_name", editable: true, resizable: true, type: "text" },
			   	{ key: "locationCode", name: "location_code", editable: true, resizable: true, type: "text" },
			   	{ key: "locationName", name: "location_name", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationCode", name: "polling_station_code", editable: true, resizable: true, type: "number" },
			   	{ key: "pollingStationName", name: "polling_station_name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceCode", name: "province_code", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceName", name: "province_name", editable: true, resizable: true, type: "text" },
			   	{ key: "ruralLocation", name: "rural_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "zoneCode", name: "zone_code", editable: true, resizable: true, type: "text" },
			   	{ key: "zoneName", name: "zone_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.districtCode == "")
					data.districtCode = null;
				if(data.districtName == "")
					data.districtName = null;
				if(data.islandCode == "")
					data.islandCode = null;
				if(data.islandName == "")
					data.islandName = null;
				if(data.locationCode == "")
					data.locationCode = null;
				if(data.locationName == "")
					data.locationName = null;
				if(data.pollingStationName == "")
					data.pollingStationName = null;
				if(data.provinceCode == "")
					data.provinceCode = null;
				if(data.provinceName == "")
					data.provinceName = null;
				if(data.zoneCode == "")
					data.zoneCode = null;
				if(data.zoneName == "")
					data.zoneName = null;
				return data
			}
		},
		'locations-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "activeLocation", name: "active_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "customLocation", name: "custom_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "districtCode", name: "district_code", editable: true, resizable: true, type: "text" },
			   	{ key: "districtName", name: "district_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "islandCode", name: "island_code", editable: true, resizable: true, type: "text" },
			   	{ key: "islandName", name: "island_name", editable: true, resizable: true, type: "text" },
			   	{ key: "locationCode", name: "location_code", editable: true, resizable: true, type: "text" },
			   	{ key: "locationName", name: "location_name", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationCode", name: "polling_station_code", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationName", name: "polling_station_name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceCode", name: "province_code", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceName", name: "province_name", editable: true, resizable: true, type: "text" },
			   	{ key: "ruralLocation", name: "rural_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "zoneCode", name: "zone_code", editable: true, resizable: true, type: "text" },
			   	{ key: "zoneName", name: "zone_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.districtCode == "")
					data.districtCode = null;
				if(data.districtName == "")
					data.districtName = null;
				if(data.islandCode == "")
					data.islandCode = null;
				if(data.islandName == "")
					data.islandName = null;
				if(data.locationCode == "")
					data.locationCode = null;
				if(data.locationName == "")
					data.locationName = null;
				if(data.pollingStationCode == "")
					data.pollingStationCode = null;
				if(data.pollingStationName == "")
					data.pollingStationName = null;
				if(data.provinceCode == "")
					data.provinceCode = null;
				if(data.provinceName == "")
					data.provinceName = null;
				if(data.zoneCode == "")
					data.zoneCode = null;
				if(data.zoneName == "")
					data.zoneName = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}

