
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { ISSUED_DOCUMENTS_ORDER_BY_ID } from "../../../auto/js/metadata/IssuedDocumentsOrderBy";
import { DOCUMENT_TYPE_E_ID } from "../../../auto/js/metadata/DocumentType";

export const issuedIdCardColumns = [
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "firstname", field: "firstname"},
    {title: "fourthname", field: "fourthname"},
    {title: "birthdate", field: "birthdate"},
    {title: "E_Id", field: "id"},
];

export const buildData = async (query) => {
    let filter = query;
    let data;
    filter["issued-documents"] = {documentTypeList: [DOCUMENT_TYPE_E_ID]};
    filter["orderBy"] = ISSUED_DOCUMENTS_ORDER_BY_ID;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["issued-documents"].columns.forEach(element => {
            if(element.type=='text'){
                filter["issued-documents"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getIssuedDocumentData().then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getIssuedDocumentData = async () => {
    return await rest.request(getServiceUri() + "custom/get-e-id-issued-documents/", "GET");
}

const countData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/count/search-by-document-type/", "POST", filter["issued-documents"]);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        row.id = formatId(row.id.toString())
        row.vitalRecordId = formatId(row.vitalRecordId.toString())
        let date = row.birthdate
        if (date !== null)
        row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
        newRows.push(row);
    }
    return newRows;
}

function formatId(eId) {
    if (eId.length < 9) {
        let diff = 9 - eId.length
        while (diff !== 0) {
            eId = "0" + eId;
            diff = diff - 1;
        }
    }
    let newString = eId.slice(0, 3) + '-' + eId.slice(3);
    let finalForm = newString.slice(0, 7) + '-' + newString.slice(7)
    return finalForm
}