export const DocumentType = {
	"ID_CARD": 1
	,
	"PASSPORT": 2
	,
	"RESIDENCE_CARD": 3
	,
	"E_ID": 4
	
}
Object.freeze(DocumentType);
const toTypeMap = {};
Object.keys(DocumentType).forEach(key => {
	toTypeMap[DocumentType[key]] = key;
});
export function getDocumentType (value) {
	return toTypeMap[value];
}

export function getAllDocumentType () {
    return Object.keys(DocumentType);
}

export const DOCUMENT_TYPE_ID_CARD = "ID_CARD";
export const DOCUMENT_TYPE_PASSPORT = "PASSPORT";
export const DOCUMENT_TYPE_RESIDENCE_CARD = "RESIDENCE_CARD";
export const DOCUMENT_TYPE_E_ID = "E_ID";
