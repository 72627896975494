import * as React from 'react';
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";

export function TextInput (props) {
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
  	const { setValue } = helpers;
	const {currentValue, setCurrentValue} = React.useState(value);

	const handleChange = event => {
		setCurrentValue(event.target.value);
	};
	const saveChanges = event => {
		setValue(event.target.value)
	}
	return (
		<TextField
		id={props.name}
		label={props.label}
		multiline
		rowsMax="4"
		value={value}
		onChange={handleChange}
		onBlur={saveChanges}
		type={props.type}
		disabled={props.disabled}
		/>
	);
}