import { displayApprovedBirthRegistrationList } from '../../../auto/js/lists/birthRegistration/ApprovedBirthRegistrationsList'
import { displayPendingBirthRegistrationsList } from '../../../auto/js/lists/birthRegistration/PendingBirthRegistrationsList'
import { displayReadyBirthRegistrationsList } from '../../../auto/js/lists/birthRegistration/ReadyBirthRegistrationsList'
import { displayRejectedBirthRegistrationsList } from '../../../auto/js/lists/birthRegistration/RejectedBirthRegistrationList'
import { displayCivilRecordsList } from '../../../auto/js/lists/CivilRecordList'
import { displayInactiveCivilRecordsList } from '../../../auto/js/lists/InactiveCivilRecordList'
import { displayNewOccupationForm } from '../../../auto/js/forms/metadata/OccupationForm'
import { displayNewTranslationsForm } from '../../../auto/js/forms/metadata/TranslationsForm'
import { displayNewCountryForm } from '../../../auto/js/forms/metadata/CountryForm'
import { displayCountriesList } from '../../../auto/js/lists/metadata/CountriesList'
import { displayOccupationList } from '../../../auto/js/lists/metadata/OccupationList'
import { displayTranslationsList } from '../../../auto/js/lists/metadata/TranslationsList'
import { displayApprovedDeathRegistrationList } from '../../../auto/js/lists/deathRegistartion/ApprovedDeathRegistartionList'
import { displayPendingDeathRegistrationList } from '../../../auto/js/lists/deathRegistartion/PendingDeathRegistartionList'
import { displayRejectedDeathRegistrationList } from '../../../auto/js/lists/deathRegistartion/RejectedDeathRegistrationList'
import { displayDeathRecordList } from '../../../auto/js/lists/DeathRecordList'
import { displayApprovedIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/ApprovedIdCardApplicationList'
import { displayPendingIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/PendingIdCardApplicationList'
import { displayRejectedIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/RejectedIdCardApplicationList'
import { displayReadyIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/ReadyIdCardApplicationList'
import { displayIdCardList } from '../../../auto/js/lists/IssuedIdCardList'
import { whoami } from '../../../auto/js/users/UserInfo';

export const menu = () => {
	return {
		...((whoami().roles.includes('ALL') || whoami().roles.includes('REGISTRAR')) && {
			"civil-records": {
				submenu: {
					"birth-registration": {
						options: {
							approved: { label: "Approved", do: displayApprovedBirthRegistrationList },
							pending: { label: "Pending", do: displayPendingBirthRegistrationsList },
							rejected: { label: "Rejected", do: displayRejectedBirthRegistrationsList },
							...((whoami().roles.includes('REGISTRAR') || whoami().roles.includes('ALL')) && {
								ready: { label: "Ready", do: displayReadyBirthRegistrationsList },
							})
						},
						label: "Birth Registration"
					}/* ,
					"books": {
						options: {
							search: { label: "Civil Records", do: displayCivilRecordsList }
						},
						label: "Books"
					} */
				},
				label: "Birth"
			},
			"death-records": {
				submenu: {
					"death-registration": {
						options: {
							approved: { label: "Approved", do: displayApprovedDeathRegistrationList },
							pending: { label: "Pending", do: displayPendingDeathRegistrationList },
							rejected: { label: "Rejected", do: displayRejectedDeathRegistrationList }
						},
						label: "Death Registration"
					},
					other: {
						options: {
							search: { label: "Death Records", do: displayDeathRecordList }
						}
					}
				},
				label: "Death"
			},
			"id-database": {
				submenu: {
					other: {
						options: {
							search: { label: "Issued Documents", do: displayIdCardList }
						}
					}
				},
				label: "ID Cards"
			}
		}),
		/* ...((whoami().roles.includes('ALL') || whoami().roles.includes('REGISTRAR')) && {
			"crvs-admin": {
				submenu: {
					"occupations": {
						options: {
							list: { label: "Occupations", do: displayOccupationList },
							new: { label: "New", do: displayNewOccupationForm },
						},
						label: "Occupations"
					},
					"countries": {
						options: {
							list: { label: "Countries", do: displayCountriesList },
							new: { label: "New", do: displayNewCountryForm },
						},
						label: "Countries"
					},
					"translations": {
						options: {
							list: { label: "Translations", do: displayTranslationsList },
							new: { label: "New", do: displayNewTranslationsForm },
						},
						label: "Translations"
					},
				},
				label: "Configuration"
			}
		}), */
	}
}
